import React from "react";
import { Container, Grid } from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { AdBanner } from "API";

type Props = {
  position: "topheader" | "topfooter" | "ad" | "pr";
  adBanners: {
    topheader: AdBanner[];
    topfooter: AdBanner[];
    ad: AdBanner[];
    pr: AdBanner[];
  };
};

function Page(props: Props) {
  const { position, adBanners } = props;
  const _adBanners = adBanners[position];

  return (
    <>
      {_adBanners && _adBanners.length > 0 && (
        <Container
          style={{
            boxShadow: "none",
            marginBottom: "50px",
          }}
        >
          <Grid container>
            {_adBanners.map((_adBanner: any, key: any) => (
              <Grid item xs={12} sm={6} key={key} style={{ padding: "6px" }}>
                <a href={_adBanner.ad.url} rel="noreferrer" target="_blank">
                  {_adBanner.media && (
                    <img
                      src={_adBanner.media.url}
                      alt={_adBanner.media.name}
                      style={{
                        width: "100%",
                        minWidth: "260px",
                      }}
                    />
                  )}
                </a>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default withWidth()(Page);
