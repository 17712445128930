import React from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import withWidth from "@material-ui/core/withWidth";
import { AdBanner } from "API";

type Props = {
  position: "topheader" | "topfooter" | "ad" | "pr";
  adBanners: {
    topheader: AdBanner[];
    topfooter: AdBanner[];
    ad: AdBanner[];
    pr: AdBanner[];
  };
  title: string;
};

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: "none",
    marginTop: "4rem",
    marginBottom: "4rem",
    [theme.breakpoints.up("sm")]: {
      marginBottom: "8rem",
    },
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      placeContent: "center",
    },
    [theme.breakpoints.only("xs")]: {
      placeContent: "end",
    },
  },
  link: {
    border: "1px solid #ddd",
    padding: "2px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "5rem",
    maxWidth: "11rem",
    [theme.breakpoints.up("sm")]: {
      padding: "4px",
      height: "6.5rem",
      minWidth: "11rem",
      maxWidth: "14rem",
    },
    [theme.breakpoints.up("md")]: {
      padding: "6px",
      height: "8.7rem",
      minWidth: "14rem",
      width: "100%",
      maxWidth: "18rem",
    },
  },
  image: {
    maxWidth: "10rem",
    [theme.breakpoints.up("sm")]: {
      minWidth: "10rem",
      maxWidth: "13rem",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: "13rem",
      maxWidth: "17rem",
    },
  },
}));

const divider = <Box width="2.5rem" borderBottom="2px solid #ccc" />;

function Page(props: Props) {
  const classes = useStyles();
  const { position, title, adBanners } = props;

  const _adBanners = adBanners[position];

  return (
    <>
      {_adBanners && _adBanners.length > 0 && (
        <Container className={classes.container}>
          <Grid container className={classes.gridContainer}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                marginBottom: "1rem",
              }}
            >
              {divider}
              <Typography
                variant="h5"
                style={{
                  color: "gray",
                  fontSize: "1rem",
                }}
              >
                {title}
              </Typography>
              {divider}
            </Grid>

            {_adBanners.map((adBanner: any, key: any) => (
              <Grid
                item
                xs={6}
                sm={4}
                md={3}
                key={key}
                style={{
                  padding: ".5rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <a
                  href={adBanner.ad.url}
                  rel="noreferrer"
                  target="_blank"
                  className={classes.link}
                >
                  {adBanner.media && (
                    <img
                      loading="lazy"
                      src={adBanner.media.url}
                      className={classes.image}
                      alt={adBanner.media.name}
                    />
                  )}
                </a>
              </Grid>
            ))}
          </Grid>
        </Container>
      )}
    </>
  );
}

export default withWidth()(Page);
