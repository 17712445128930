import React from "react";
import Adblock from "components/organisims/Adblock";
import { PartialHtml, Publication, Tag, Article, AdBanner } from "API";
import Header from "components/uikit/Header/Header";
import HtmlSection from "components/molecures/Sections/HtmlSection";
import KaziMovie from "components/organisims/home/KaziMovie";
import Publications from "components/sections/publication/Publications";
import PickupTags from "components/organisims/home/PickupTags";
import PublicationsMobile from "components/sections/publication/PublicationsMobile";
import PickupTagsMobile from "components/organisims/home/PickupTagsMobile";
import KaziMovieMobile from "components/organisims/home/KaziMovieMobile";
import ResponsiveComponent from "components/atoms/ResponsiveComponent";
import NewArticlesMobile from "components/sections/new-articles/NewArticlesMobile";
import NewArticles from "components/sections/new-articles/NewArticles";
import PopularArticles from "components/sections/popular-articles/PopularArticles";
import PopularArticlesMobile from "components/sections/popular-articles/PopularArticlesMobile";
import CategoryComponent from "components/sections/category/CategoryComponent";
import CategoryComponentMobile from "components/sections/category/CategoryComponentMobile";
import AdblockFlex from "components/organisims/AdblockFlex";

type Props = {
  articles: Article[];
  pickUpArticles: Article[];
  partialHtmls: PartialHtml[];
  categories: {
    slug: string;
    name: string;
    articles: Article[];
  }[];
  youtubes: {
    priority: number;
    src: string;
  }[];
  publications: Publication[];
  tags: Tag[];
  adBanners: {
    topheader: AdBanner[];
    topfooter: AdBanner[];
    ad: AdBanner[];
    pr: AdBanner[];
  };
};

export default function Home(props: Props) {
  const {
    articles,
    pickUpArticles,
    partialHtmls,
    categories,
    youtubes,
    publications,
    tags,
    adBanners,
  } = props;

  const partialHtmlSP = partialHtmls.find(
    (partialHtml) => !partialHtml.name.includes("PC")
  );
  const partialHtmlPC = partialHtmls.find((partialHtml) =>
    partialHtml.name.includes("PC")
  );

  return (
    <>
      <Header
        color="white"
        brand="Kazi ONLINE"
        fixed
        changeColorOnScroll={{
          height: 0,
          color: "white",
        }}
      />

      {/* 新着記事 */}
      {articles.length > 3 && (
        <ResponsiveComponent
          sp={<NewArticlesMobile articles={articles ?? []} />}
          pc={<NewArticles articles={articles ?? []} />}
        />
      )}

      {/* 広告 */}
      <Adblock position="topheader" adBanners={adBanners} />

      {/* 人気記事 */}
      {articles && (
        <ResponsiveComponent
          sp={<PopularArticlesMobile pickUpArticles={pickUpArticles} />}
          pc={<PopularArticles pickUpArticles={pickUpArticles} />}
        />
      )}

      {/* カテゴリー */}
      {categories && (
        <div style={{ backgroundColor: "white", marginBottom: "50px" }}>
          <ResponsiveComponent
            sp={<CategoryComponentMobile categories={categories} />}
            pc={<CategoryComponent categories={categories} />}
          />
        </div>
      )}

      {/* 差し込みhtml */}
      {(partialHtmlSP?.contents?.length ?? 0 > 0) && (
        <ResponsiveComponent
          sp={
            <div style={{ backgroundColor: "white" }}>
              <HtmlSection data={partialHtmlSP?.contents} />
            </div>
          }
          pc={
            <div style={{ backgroundColor: "white" }}>
              {partialHtmlPC?.contents?.length ?? 0 > 0 ? (
                <HtmlSection data={partialHtmlPC?.contents} />
              ) : (
                <HtmlSection data={partialHtmlSP?.contents} />
              )}
            </div>
          }
        />
      )}

      {/* タグ */}
      {tags.length > 0 && (
        <div style={{ backgroundColor: "white", marginBottom: "50px" }}>
          <ResponsiveComponent
            sp={
              <div style={{ backgroundColor: "white" }}>
                <PickupTagsMobile tags={tags} />
              </div>
            }
            pc={
              <div style={{ backgroundColor: "white" }}>
                <PickupTags tags={tags} />
              </div>
            }
          />
        </div>
      )}

      {/* youtube */}
      {youtubes.length > 0 && (
        <div style={{ marginBottom: "50px" }}>
          <ResponsiveComponent
            sp={
              <div style={{ backgroundColor: "white" }}>
                <KaziMovieMobile youtubes={youtubes} />
              </div>
            }
            pc={
              <div style={{ backgroundColor: "white" }}>
                <KaziMovie youtubes={youtubes} />
              </div>
            }
          />
        </div>
      )}

      {/* Footer広告 */}
      <Adblock position="topfooter" adBanners={adBanners} />

      {/* AD広告 */}
      <AdblockFlex position="ad" title="AD" adBanners={adBanners} />

      {/* PR広告 */}
      <AdblockFlex position="pr" title="PR" adBanners={adBanners} />

      {/* 発行物 */}
      {publications && publications.some((item) => item !== null) && (
        <>
          <ResponsiveComponent
            sp={
              <div style={{ backgroundColor: "white" }}>
                <PublicationsMobile publications={publications} />
              </div>
            }
            pc={
              <div style={{ backgroundColor: "white" }}>
                <Publications publications={publications} />
              </div>
            }
          />
        </>
      )}
    </>
  );
}
